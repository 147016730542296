<template>
  <div id="app">
    <nav>
      <div class="left">
        <div class="logo">
          <router-link to="/"><img src="./assets/images/logo.png"></router-link>
        </div>
        <div class="menu">
          <router-link to="/Through">{{ $t('content.menu.product') }}</router-link>
          <!-- <router-link to="/UserGuide">Usage Tutorial</router-link> -->
          <router-link to="/CompanyProfile">{{ $t('content.menu.company') }}</router-link>
          <router-link to="/ServicesFeatures">{{ $t('content.menu.services') }}</router-link>
          <router-link to="/CreatorCommunity">{{ $t('content.menu.creatorCommunity') }}</router-link>
          <router-link to="/About">{{ $t('content.menu.about') }}</router-link>
        </div>
      </div>
      <div class="download">
        <a href="https://play.google.com/store/apps/details?id=com.fos.metaverse.overseas&pli=1" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-android2"
            viewBox="0 0 16 16">
            <path
              d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
          </svg>
          <div>
            {{ $t('content.menu.download') }}
          </div>
        </a>
        <a href="https://apps.apple.com/us/app/future-crosser/id6479590085" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-apple"
            viewBox="0 0 16 16">
            <path
              d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
            <path
              d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
          </svg>
          <div>
            {{ $t('content.menu.download') }}
          </div>
        </a>
      </div>
    </nav>
    <router-view />
    <footer>
      <div class="footer-floor1">
        <div class="footer-floor1-panel1">
          <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162" class="trans-btn">{{
            $t('content.menu.bookNow') }}</router-link>
          <router-link to="/EnquireNow" class="trans-btn">{{ $t('content.menu.inquireNow') }}</router-link>
        </div>
        <div class="footer-floor1-panel2">
          <router-link to="/CompanyProfile" class="trans-btn">{{ $t('content.menu.aboutUs') }}</router-link>
          <!-- <router-link to="/Meta" class="trans-btn">Future metaverse</router-link> -->
          <router-link to="/Ar" class="trans-btn">Future AR</router-link>
          <router-link to="/Sdk" class="trans-btn">{{ $t('content.menu.sdk') }}</router-link>
        </div>
      </div>
      <div class="footer-floor2">
        <a href="javascript:;" :class="['trans-btn', { 'trans-btn-active': this.language == 'en' }]"
          @click="btn_language('en')">United States</a>
        <i style="color: #C5C5C5;">|</i>
        <a href="javascript:;" :class="['trans-btn', { 'trans-btn-active': this.language == 'zh' }]"
          @click="btn_language('zh')">中国</a>
      </div>
      <div class="footer-floor3">
        <div class="item-child">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
            <path
              d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
          </svg>
          <p>VISIONARY FUTURE PTE. LTD.</p>
        </div>
        <div class="item-child">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt"
            viewBox="0 0 16 16">
            <path
              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          <p>Tong Eng Building101 Cecil Street, #13-03, Singapore</p>
        </div>
        <div class="item-child">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at"
            viewBox="0 0 16 16">
            <path
              d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
            <path
              d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
          </svg>
          <p>inquiry@visionary-victory.com</p>
        </div>
      </div>
      <div class="footer-floor4">
        <div class="item-child">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard"
            viewBox="0 0 16 16">
            <path
              d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
            <path fill-rule="evenodd"
              d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
          </svg>
          <router-link to="/Privacy" target="_blank" class="trans-btn">{{ $t('content.menu.privacy') }}</router-link>
        </div>
        <div class="item-child">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
          </svg>
          <router-link to="/UserAgreementen" target="_blank" class="trans-btn">{{ $t('content.menu.userAgreementen')
          }}</router-link>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "hyyakuheiw";
  src: url('./font-familys/hyyakuheiw.ttf')
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  z-index: -10;
  height: auto;
  margin: auto;
}

body {
  position: relative;
  z-index: -100;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

nav {
  opacity: 0.8;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: left;
    align-items: center;

    .logo {

      // width: 8%;
      img {
        width: auto;
        height: 40px;
        margin-left: 30px;
        margin-right: 50px;
      }
    }

    .menu {
      // width: 82%;
      display: flex;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 50px;

      a {
        color: #878787;
        cursor: pointer;
        transition: 0.5s;
        text-decoration: none;

        &.router-link-exact-active {
          color: white;
        }
      }

      a:hover {
        color: white;
      }

    }
  }

  .download {
    // width: 10%;
    margin-right: 20px;
    display: flex;
    justify-content: right;
    align-items: center;
    column-gap: 20px;

    a {
      border: 1px solid white;
      border-radius: 50px;
      padding: 5px 15px;
      color: #B5B5B5;
      text-decoration: none;
      white-space: nowrap;
      display: flex;
      justify-content: left;
      align-items: center;
      column-gap: 5px;

      svg{
        width: 16px;
        height: 16px;
      }
    }

    a:hover {
      color: white;
    }

    // .top-btn {
    //   color: #B5B5B5;
    //   background-color: transparent;
    //   border: 1px solid white;
    //   border-radius: 50px;
    //   padding: 3px 15px;
    // }

    // .top-btn:hover {
    //   color: white;
    // }
  }
}

.trans-btn {
  text-decoration: none;
  color: #C5C5C5;
  display: block;
}

.trans-btn-active {
  color: white;
}

footer {
  width: 100%;
  height: auto;
  background-color: black;

  .footer-floor1 {
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 3rem 8rem 2rem 8rem;

    .footer-floor1-panel1 {
      // border: 1px solid gold;

      .trans-btn {
        border: 1px solid white;
        border-radius: 50px;
        margin-bottom: 1.5rem;
        padding: 5px 20px;
      }

      .trans-btn:hover {
        color: black;
        background-color: white;
      }
    }

    .footer-floor1-panel2 {
      // border: 1px solid green;
      text-align: left;
      width: 15rem;

      .trans-btn {
        color: #C5C5C5;
        height: 3rem;
        line-height: 3rem;

        &.router-link-exact-active {
          color: white;
          font-weight: bold;
        }
      }

      .trans-btn:hover {
        color: white;
        font-weight: bold;
      }
    }
  }

  .footer-floor2 {
    // border: 1px solid gold;
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 1rem;
    padding: 0 0 1rem 8rem;
  }

  .footer-floor3 {
    // border: 1px solid green;
    color: #C5C5C5;
    font-size: 0.8rem;
    padding: 1rem 0;
    border-top: 1px solid #414345;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;

    .item-child {
      // border: 1px solid red;
      display: flex;
      justify-content: left;
      align-items: center;
      // width: 400px;
      margin-bottom: 10px;
      column-gap: 10px;

      p {
        margin: 0;
      }
    }
  }

  .footer-floor4 {
    // border: 1px solid green;
    color: #C5C5C5;
    font-size: 0.8rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;

    .item-child {
      // border: 1px solid red;
      display: flex;
      justify-content: left;
      align-items: center;
      // width: 400px;
      margin-bottom: 10px;
      column-gap: 10px;

      p {
        margin: 0;
      }
    }
  }
}

.trunk {
  position: relative;
  z-index: -5;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.floor {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .font-absolute {
    position: absolute;
    z-index: 1;
    // border: 1px solid red;
    width: 100%;
  }
}

.c-btn {
  border: none;
  border-radius: 50px;
  padding: 5px 30px;
}


.bottom-shadow {
  background-image: url('./assets/images/video-buttom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0.9;
}

/* 包裹箭头效果的盒子 */
.arrowSS {
  animation: bounce-inSS 2s infinite;
  position: relative;
  /* 启动动画特效 */
}

// /* 箭头效果的盒子 */
// .arrowSS_style {
//   cursor: pointer;
// }
/* 箭头动画 */
@keyframes bounce-inSS {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-3vh);
  }

  60% {
    transform: translateY(-1vh);
  }
}


.modal-back {
  background-color: rgba(100, 100, 100, 0.5);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
<script>
import { getQrCode } from './utils/api';
export default {
  data() {
    return {
      qrCodeImg: '',
      qrCodeUrl: '',
      language: localStorage.getItem("lang") || "en"
    }
  },
  created() {
    // console.log(this.language);
    this.windowsChange();
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
    }
  },
  methods: {
    btn_language: function (type) {
      switch (type) {
        case 'en':
          localStorage.setItem("locale", "en");
          this.$i18n.locale = localStorage.getItem("locale");
          localStorage.setItem("lang", "en");
          // alert('切换到英文');
          break;
        case 'zh':
          localStorage.setItem("locale", "zh");
          this.$i18n.locale = localStorage.getItem("locale");
          localStorage.setItem("lang", "zh");
          // alert('切换到中文');
          break;
        default:
          break;
      }
      location.reload();
    },
    to_download: function () {
      let _self = this;
      getQrCode(null).then(
        response => {
          if (response.code == 200) {
            let data = response.data;
            _self.qrCodeImg = data.imgbase;
            _self.qrCodeUrl = data.number;
            window.location.href = _self.qrCodeUrl;
          }
        }
      ).catch(error => {
        console.log('error:' + error);
      });
    },
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw < 850) {
        window.location.href = 'https://m.visionary.future.top/'
      }
    }
  }
}
</script>
